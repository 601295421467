import { Box, Flex } from "@raca2022/uikit";
import styled from "styled-components";

export const FootterAndOutTeam = styled(Box)`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 40px 36px 20px;
  max-width: 1920px;
  margin: auto;

  @media only screen and (max-width: 743px) {
    padding: 4vw 7.5vw;
  }
`;
export const FooterAndOutTeamContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
`;
export const Outteam = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: auto;
  padding-top: 20px;
  ::-webkit-scrollbar {
    display: none;
  }
`;
export const OutteamFloor = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 1348px;
  padding: 0 46px;
  @media only screen and (max-width: 769px) {
    padding: 0 12px;
  }
`;
export const Title = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  padding: 0 46px;
  padding-top: 50px;
  h1 {
    font-size: 48px;
    font-weight: 700;
    letter-spacing: 0.02em;
    text-align: center;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1),
      rgba(0, 0, 0, 0)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media only screen and (max-width: 769px) {
    padding: 0 12px;
    margin-top: 40px;
  }
  @media only screen and (max-width: 640px) {
    h1 {
      font-size: 30px;
    }
  }
`;
export const Teams = styled(Box)`
  width: 23%;
  max-width: 323px;
  height: 157px;
  border-radius: 8px;
  padding-top: 45px;
  padding-left: 25px;
  position: relative;
  p {
    font-size: 10px;
    font-weight: 400;
    line-height: 17px;
    margin-top: 10px;
  }
  h1 {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 10px;
  }
  img {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;
export const FootterFootball = styled(Flex)`
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 4.4vw;

  .view_sp {
    font-size: 16px;
    line-height: 24px;
    color: #fff;
  }

  .view_coppy {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #fff;
  }

  @media only screen and (max-width: 980px) {
    flex-direction: column;

    &:nth-child(2) {
      width: 100%;
    }
  }
  @media only screen and (min-width: 1440px) {
    padding-bottom: 64px;
  }
  @media only screen and (max-width: 743px) {
    .view_sp {
      font-size: 4.375vw;
    }
  }
`;
export const FooterItem = styled(Box)`
  width: 19%;
  gap: 10px;
  position: relative;
  h1 {
    position: relative;
    font-family: OrbitronBold;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    padding-bottom: 8px;
  }
  p {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #fff;
  }
  img {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    transition: all 0.3s linear;
  }
  @media only screen and (max-width: 475px) {
    width: 100%;
    h1,
    p {
      text-align: left;
    }
  }
  @media only screen and (max-width: 743px) {
    width: 50%;
    &:not(:last-child) {
      margin-bottom: 11.25vw;
    }
    a {
      font-size: 5vw;
    }
    h1 {
      padding-bottom: 0;
      font-size: 5.6vw;
      margin-bottom: 5vw;
    }
  }
`;

export const SOCIAL = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 28px;
  flex-wrap: wrap;

  @media only screen and (max-width: 500px) {
    gap: 0;
    row-gap: 30px;

    & > * {
      width: 25%;
      text-align: center;
    }
  }

  img {
    max-width: 34px;
    max-height: 27px;
    :last-child {
      margin: 0px;
    }
  }
`;
export const CoppyRight = styled(Box)`
  text-align: center;
  small {
    font-size: 14px;
  }
  @media only screen and (max-width: 743px) {
    small {
      font-size: 3.75vw;
      line-height: 150%;
    }
  }
`;
export const FooterLogo = styled(Box)`
  width: 100%;
  margin-right: 60px;
  img {
    max-width: 100%;
  }

  @media only screen and (max-width: 768px) {
    margin-right: 0px;
  }
`;

export const BoxIconText = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: 10px;
  p {
    font-weight: 600;
    font-size: 12.6px;
    line-height: 13px;
    color: #fff;
    margin-right: 10px;
  }
`;

export const FooterConten = styled(Flex)`
  padding-left: 172px;
  padding-top: 70px;
  flex: 1;
  align-items: flex-start;

  @media only screen and (max-width: 1440px) {
    flex-wrap: wrap;
    padding-left: 80px;
  }

  @media only screen and (max-width: 1024px) {
    flex-wrap: wrap;
    padding-left: 0px;
  }

  @media only screen and (max-width: 980px) {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
  @media only screen and (max-width: 475px) {
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
  }
  @media only screen and (max-width: 743px) {
    padding-top: 11.25vw;
  }
`;
export const FooterItemElm = styled(Box)<{ height?: string }>`
  height: fit-content;
  p {
    cursor: pointer;
  }
  a {
    display: block;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    #telegram {
      position: relative;
      top: -4px;
    }
  }
  @media only screen and (max-width: 475px) {
    height: ${({ height }) => height};
    overflow: hidden;
    transition: all 0.3s linear;
  }
  @media only screen and (max-width: 743px) {
    a {
      margin-bottom: 2.5vw;
      font-size: 5vw;
      #twitter {
        width: 5vw;
        height: 5vw;
      }
      #discord {
        width: 6.5vw;
        height: 5vw;
      }
      #telegram {
        width: 5vw;
        height: 5vw;
        top: 0;
      }
    }
  }
`;

export const LogoFooter = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 8px;
  p {
    margin-left: 16px;
    font-size: 24px;
    font-family: OrbitronBold;
  }
  @media only screen and (max-width: 743px) {
    margin-bottom: 2.5vw;
    p {
      font-size: 7.5vw;
    }
    #logoAirPayOne {
      width: 10.6vw;
      height: 9.3vw;
    }
  }
`;
